main {
    background-image: url("../public/background.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    height: 100%;
    position: fixed;
    width: 100%;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    height: 100vh;
    text-align: center;
}

.placeholder-text {
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    /*margin: 20px;*/
}

.headline {
    padding-top: 50px;
    margin-bottom: 5px;
}

.headline2 {
    padding-top: 40px;
    margin-bottom: 5px;
}

.legend {
    font-size: calc(4px + 2vmin);
}

.series-text {
    margin-right: 10px;
    color: white;
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
    text-decoration: underline;
    text-decoration-color: purple;
}

.movie-text {
    margin-right: 10px;
    color: white;
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
    text-decoration: underline;
    text-decoration-color: red;
}

.oneshot-text {
    margin-right: 10px;
    color: white;
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
    text-decoration: underline;
    text-decoration-color: gold;
}

.alt-timeline-text {
    margin-right: 10px;
    color: white;
    font-weight: bold;
    display: inline-block;
    overflow: hidden;
    height: 100%;
}


