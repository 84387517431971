.home-logo {
    height: 30vmin;
    pointer-events: none;
    margin-top: 10px;
}

@keyframes home-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .home-logo {
        animation: home-logo-spin infinite 20s linear;
    }
}

.greeting {
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin: 40px;
    padding-top: 20px;
}